import {
  Form,
  Link,
  NavLink,
  useActionData,
  useSearchParams,
  useSubmit,
} from "@remix-run/react";
import { useEffect, useState } from "react";
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  redirect,
} from "@remix-run/node";
import { commitSession, getSession } from "~/.server/session";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getAuth as getAdminAuth } from "firebase-admin/auth";
import { firebaseAdmin } from "~/.server/firebase";
import { auth } from "~/firebase.client";
import { trackServerEvent } from "~/analytics.server";
import { Events } from "~/config";
import api from "~/.server/api";

import { Button, FormField } from "~/components";
import { Input } from "~/components";
import { z } from "zod";
import { GeolocationResponse, getGeolocation, validateForm } from "~/lib/utils";
import { getSignupParams } from "~/.server/utils";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

const invalidLoginError = "Invalid email and/or password. Please try again.";

export default function LoginScreen() {
  const actionData = useActionData<typeof action>();
  const error = actionData?.error;

  const submit = useSubmit();

  const [errorMessage, setErrorMessage] = useState(error || "");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [geo, setGeo] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    getGeolocation().then((geo) => {
      if (geo) {
        setGeo(JSON.stringify(geo));
      }
    });
  }, []);

  const handleEmailLogin = async () => {
    setErrorMessage("");
    try {
      const userCred = await signInWithEmailAndPassword(auth, email, password);
      const idToken = await userCred.user.getIdToken();
      submit(
        {
          idToken,
        },
        { method: "post" }
      );
    } catch (err) {
      console.log(err);
      setErrorMessage(invalidLoginError);
    }
  };

  return (
    <div
      className="Login-container min-h-screen bg-cover bg-center flex justify-center items-center"
      style={{
        background: "url('/home-bg.jpeg')",
        backgroundSize: "cover",
        backgroundPosition: "top",
      }}
    >
      <div className="formBox p-6 rounded-lg  w-full md:max-w-lg max-w-[90%] bg-white bg-opacity-60">
        <div className="LoginLogo mb-10 flex justify-center">
          <img src="/flirtybumblack.svg" alt="FlirtyBum Logo" />
        </div>

        <div className="text-center mb-5">
          <h1 className="text-xl font-bold text-gray-800">
            Sign In to FlirtyBum
          </h1>
        </div>

        {(errorMessage || error) && (
          <div className="mb-5 text-sm text-red-500">
            {errorMessage || error}
          </div>
        )}

        <form className="space-y-4">
          {/* Email */}
          <FormField label="Email">
            <Input
              type="text"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormField>

          {/* Password */}
          <FormField label="Password">
            <div className="relative">
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowPassword((prev) => !prev)}
                className="absolute inset-y-0 right-2 flex items-center text-gray-500 hover:text-gray-700"
              >
                {showPassword ? (
                  <IoEyeOffOutline size={20} />
                ) : (
                  <IoEyeOutline size={20} />
                )}
              </button>
            </div>
            <div className="text-right mt-2">
              <Link
                to="/auth/forgot-password"
                className="text-sm text-black hover:underline"
              >
                Forgot Password?
              </Link>
            </div>
          </FormField>

          {/* Login Button */}
          <Button
            disabled={!email || !password}
            type="button"
            variant="default"
            className="w-full py-2 rounded-lg hover:bg-blue-600"
            onClick={(e) => {
              e.preventDefault();
              handleEmailLogin();
            }}
          >
            Sign In
          </Button>
        </form>

        <div className="RegisterLink text-center mt-5">
          <p className="text-gray-700">
            Not a member?{" "}
            <NavLink
              to="/auth/register"
              className="font-bold text-black hover:underline"
            >
              Register
            </NavLink>
          </p>
          <p className="text-gray-700 mt-3">OR</p>

          <Form method="post" action="/auth/oauth/google">
            <input type="hidden" name="geo" value={geo} />
            <Button
              type="submit"
              className="w-full mt-3 bg-white border border-gray-300 text-gray-800 py-2 rounded-lg flex items-center justify-center hover:bg-gray-100"
            >
              <svg
                className="google-icon w-5 h-5 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 326667 333333"
              >
                <path
                  d="M326667 170370c0-13704-1112-23704-3518-34074H166667v61851h91851c-1851 15371-11851 38519-34074 54074l-311 2071 49476 38329 3428 342c31481-29074 49630-71852 49630-122593m0 0z"
                  fill="#4285F4"
                />
                <path
                  d="M166667 333333c44999 0 82776-14815 110370-40370l-52593-40742c-14074 9815-32963 16667-57777 16667-44074 0-81481-29073-94816-69258l-1954 166-51447 39815-673 1870c27407 54444 83704 91852 148890 91852z"
                  fill="#34A853"
                />
                <path
                  d="M71851 199630c-3518-10370-5555-21482-5555-32963 0-11482 2036-22593 5370-32963l-93-2209-52091-40455-1704 811C6482 114444 1 139814 1 166666s6482 52221 17777 74814l54074-41851m0 0z"
                  fill="#FBBC04"
                />
                <path
                  d="M166667 64444c31296 0 52406 13519 64444 24816l47037-45926C249260 16482 211666 1 166667 1 101481 1 45185 37408 17777 91852l53889 41853c13520-40185 50927-69260 95001-69260m0 0z"
                  fill="#EA4335"
                />
              </svg>
              Sign in with Google
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export async function loader({ request }: LoaderFunctionArgs) {
  console.log("X-Real-IP:", request.headers.get("X-Real-IP"));
  console.log("X-Forwarded-For:", request.headers.get("X-Forwarded-For"));

  const session = await getSession(request.headers.get("Cookie"));
  if (session.get("token")) {
    throw redirect("/landing-pub");
  }

  return null;
}

const loginSchema = z.object({
  idToken: z.string().min(1),
});

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  const { data, errors } = validateForm(formData, loginSchema);

  if (errors) {
    return { error: "An error occurred. Please try again." };
  }

  try {
    const auth = getAdminAuth(firebaseAdmin);
    const token = await auth.verifyIdToken(data.idToken);

    // Check if the user exists in the database
    const profile = await api.getFBProfile(token.uid);
    if (!profile) {
      return { error: invalidLoginError };
    }

    const jwt = await auth.createSessionCookie(data.idToken, {
      expiresIn: 60 * 60 * 24 * 14 * 1000,
    });

    const session = await getSession(request.headers.get("cookie"));
    session.set("token", jwt);

    const signupParams = await getSignupParams(request);
    console.log(signupParams);

    return redirect(signupParams.redirectTo || "/landing-pub", {
      headers: {
        "Set-Cookie": await commitSession(session),
      },
    });
  } catch (err) {
    console.log(err);
    return { error: invalidLoginError };
  }

  return { error: null };
}
